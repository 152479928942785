import Swiper, { Navigation, Scrollbar } from 'swiper';
import 'swiper/css';

const selectors = {
  carouselWrapper: '[data-product-carousel]',
  productTab: '[data-product-tab]',
  productGroup: '[data-product-group]',
};

class ProductCarousel extends HTMLElement {
  constructor() {
    super();

    this.productTab = this.querySelector(selectors.productTab);
    this.productGroup = this.querySelector(selectors.productGroup);
    this.productTabAll = this.querySelectorAll(selectors.productTab);
    this.productGroupAll = [...this.querySelectorAll(selectors.productGroup)];

    document.addEventListener('DOMContentLoaded', () => {
      this.carouselWrappers = this.querySelectorAll(selectors.carouselWrapper);
      this.breakpoint = window.matchMedia('(min-width:640px)');
      this.render();
    });
  }

  render() {
    // Init the carousel on load.
    this.initCarousels();

    // Init the tabs on load.
    this.initTabs();

    // Subsequently, on resize, we need to check if we need a Swiper object checking the break point.
    // If not, it will get destroyed.
    this.registerEventListeners();
  }

  initCarousels() {
    if (this.breakpoint.matches) {
      this.carousel = new Swiper(selectors.carouselWrapper, {
        modules: [Navigation, Scrollbar],
        lazy: true,
        lazyPreloadPrevNext: 2,
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        scrollbar: {
          el: '.slider-scrollbar',
          draggable: true,
          hide: false,
          snapOnRelease: false,
          dragSize: 140,
        },
        breakpoints: {
          640: {
            slidesPerView: 2.3,
            spaceBetween: 20,
          },
          744: {
            slidesPerView: 2.3,
            spaceBetween: 28,
          },
          1024: {
            slidesPerView: 3.1,
            spaceBetween: 28,
          },
          1280: {
            slidesPerView: 3.1,
            spaceBetween: 28,
          },
          1665: {
            slidesPerView: 3.1,
            spaceBetween: 30,
          }
        },
      });
    }
  }

  initTabs() {
    this.productGroup.classList.remove('hidden');
    this.productTab.classList.remove('text-brand-medium-grey', '!font-normal', 'no-underline');
    this.productTab.classList.add('text-brand-black', 'underline');

    this.productTabAll.forEach(item => {
      item.addEventListener('click', e => {
        e.preventDefault();

        this.productTabAll.forEach(tab => {
          tab.classList.remove('text-brand-black', 'underline');
          tab.classList.add('text-brand-medium-grey', '!font-normal', 'no-underline');
        });

        item.classList.remove('text-brand-medium-grey', '!font-normal', 'no-underline');
        item.classList.add('text-brand-black', 'underline');

        this.productGroupAll.forEach(current => {
          current.classList.remove('hidden');
          current.classList.remove('block');
          if (item.dataset.productTab === current.dataset.productGroup) {
            current.classList.add('block');
          } else {
            current.classList.add('hidden');
          }
        });
      });
    });
  }

  registerEventListeners() {
    window.addEventListener('resize', () => {
      this.initCarousels();
    });
  }
}

customElements.define('product-carousel', ProductCarousel);
